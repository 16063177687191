<template>
  <div class="routingRuleEdit">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('routingRulesForms.editRoutingRules') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('type') }}</label>
          <select
            id="type"
            v-model="routingRule.type"
            disabled
            class="form-control mb-4"
          >
            <option
              v-for="(type, index) in types"
              :key="`routingRules-routingRuleOption-${ index }`"
              disabled
              :value="type"
            >
              {{ type }}
            </option>
          </select>
          <template v-if="routingRule.type == types[0]">
            <label>{{ $t('from') }}</label>
            <input
              v-model="from"
              :class="['form-control', { 'is-invalid': $validator.errors.has('From') }]"
              type="text"
            >
            <span
              ref="fromText"
              :style="'color:' + fromColor"
            >
              {{ fromText }}
            </span>
            <br>
            <label>{{ $t('to') }}</label>
            <input
              v-model="to"
              :class="['form-control', { 'is-invalid': $validator.errors.has('To') }]"
              type="text"
              maxlength="12"
            >
            <span
              ref="toText"
              :style="'color:' + toColor"
            >
              {{ toText }}
            </span>
            <br>
            <label>{{ 'Insert' }}</label>
            <Multiselect
              id="insert"
              v-model="insert"            
              :options="sections"
              :custom-label="customLabel"
              label="name"
              :close-on-select="true"
              class="mb-3"
            />
            <label>{{ $t('routingRulesForms.timeElapsed') }}</label>
            <input
              v-model="routingRule.timeElapsed"
              :class="['form-control', { 'is-invalid': $validator.errors.has('TimeElapsed') }]"
              type="text"
            >
          </template>
          <template v-else-if="routingRule.type == types[1]">
            <label>{{ $t('to') }}</label>
            <input
              v-model="to"
              :class="['form-control', { 'is-invalid': $validator.errors.has('To') }]"
              type="text"
              maxlength="12"
            >
            <span
              ref="toText"
              :style="'color:' + toColor"
            >
              {{ toText }}
            </span>
            <br>
            <label>{{ 'Insert' }}</label>
            <Multiselect
              id="insert"
              v-model="insert"            
              :options="sections"
              :custom-label="customLabel"
              label="name"
              :close-on-select="true"
              class="mb-3"
            />
          </template>
          <template v-if="routingRule.type == types[2]">
            <label>{{ $t('from') }}</label>
            <input
              v-model="from"
              :class="['form-control', { 'is-invalid': $validator.errors.has('From') }]"
              type="text"
            >
            <span
              ref="fromText"
              :style="'color:' + fromColor"
            >
              {{ fromText }}
            </span>
            <br>
            <label>{{ 'Insert' }}</label>
            <Multiselect
              id="insert"
              v-model="insert"            
              :options="sections"
              :custom-label="customLabel"
              label="name"
              :close-on-select="true"
              class="mb-3"
            />
          </template>
        </div>
        <button
          class="btn btn-primary float-right"
          @click.prevent="editRoutingRule()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />{{ $t('edit') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "RoutingRuleEdit",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    errorMixin
  ],
  props: {
    routingRule: {
      type: Object,
      required: true
    },
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      sections: null,
      loading: false,
      from: null,
      to: null,
      insert: null,
      fromText: "Insert a Section ID",
      fromColor: "black",
      toText: "Insert a Section ID",
      toColor: "black",
      types: ['Routing Rule', 'Routing Rule First Section', 'Routing Rule Last Section']
    }
  },
  watch: {
    'from' () {
      if((this.from+"").length == 12) {
        this.getSection(this.from, "from");
      }
      else {
        this.fromColor = "red";
        this.fromText = "Insert a Section ID";
      }
    },
    'to' () {
      if((this.to+"").length == 12) {
        this.getSection(this.to, "to");
      }
      else {
        this.toColor= "red";
        this.toText = "Insert a Section ID";
      }
    }
  },  
  created () {
    this.from = this.routingRule.fromId;
    this.getSection(this.from, "from");
    this.to = this.routingRule.toId;
    this.getSection(this.to, "to");
    this.getSections();
  },
  methods: {
    customLabel ({ id, name }) {
      return `${id} — ${name}`
    },
    getSections () {
      this.loading = true;
      this.axios.get(`/Resort/GetResortLifts?resortId=${ this.resortId }`)
      .then(response => {
        this.sections = response.data;
        this.insert = this.sections.find(x => x.id == this.routingRule.insertId);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    async editRoutingRule () {
      this.routingRule.fromId = this.from != null ? this.from : 0;
      this.routingRule.toId = this.to != null ? this.to : 0;
      this.routingRule.insertId = this.insert != null ? this.insert.id : 0;
      await this.axios.post(`/Resort/UpdateRoutingRule?resortId=${ this.resortId }`, this.routingRule)
        .then(() => {
          this.$snotify.success(this.$t('routingRulesForms.editedSuccessfullyRoutngRules'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    async getSection (id, type) {
      await this.axios.get(`/Resort/GetSection?sectionId=${ id }`)
        .then((response) => {
          if(response && response.data && response.data.name) {
            
            if(type == "from") {
              this.fromColor = "black";
              this.fromText = `${response.data.name} (Resort: ${response.data.resortId})`;
            }
            else {
              this.toColor = "black";
              this.toText = `${response.data.name} (Resort: ${response.data.resortId})`;
            }
          }
          else {
            if(type == "from") {
              this.fromColor = "red";
              this.fromText = "Section not found";
            }
            else {
              this.toColor = "red";
              this.toText = "Section not found";
            }
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.routingRuleEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>