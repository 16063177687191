<template>
  <div class="routingRulesList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(routingRules)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(routingRules)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="fromCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ props.dataItem.type == 'Routing Rule First Section' ? null : props.dataItem.fromId }}
          </td>
        </template>

        <template
          slot="toCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ props.dataItem.type == 'Routing Rule Last Section' ? null : props.dataItem.toId }}
          </td>
        </template>

        <template
          slot="timeElapsedCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ props.dataItem.type != 'Routing Rule' ? null : props.dataItem.timeElapsed }}
          </td>
        </template>

        <div
          slot="fromIdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="fromIdInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('fromIdInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="toIdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="toIdInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('toIdInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="insertIdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="inserIdInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('insertIdInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="timeElapsedFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="timeElapsedInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('timeElapsedInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span class="hide-down-sm">{{ $t('edit') }}</span>
              </button>
              <button
                slot="button"
                class="btn btn-danger btn-sm"
                @click="openConfirmModal(props.dataItem.id, props.dataItem.type)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span class="hide-down-sm">{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <RoutingRuleAdd
          v-if="addingElement"
          :resort-id="resortId"
          @reload="reloadRoutingRules"
        />
        <RoutingRuleEdit
          v-else
          :routing-rule="routingRule"
          :resort-id="resortId"
          @reload="reloadRoutingRules"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeRoutingRule()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import RoutingRuleAdd from '@/components/Resort/RoutingRuleAdd.vue';
import RoutingRuleEdit from '@/components/Resort/RoutingRuleEdit.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "RoutingRulesList",
  components: {
    Sidebar,
    SweetModal,
    RoutingRuleAdd,
    RoutingRuleEdit
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      routingRules: null,
      routingRule: null,
      addingElement: false,
      showSidebar: false,
      deleteIndex: null,
      deleteType: null,
      kgm_columns: [
        {
          field: 'fromId',
          filterable: true,
          filter: 'text',
          title: 'From',
          filterCell: "fromIdFilter",
          cell: "fromCell"
        },
        {
          field: 'toId',
          filterable: true,
          filter: 'text',
          title: 'To',
          filterCell: "toIdFilter",
          cell: "toCell"
        },
        {
          field: 'insertId',
          filterable: true,
          filter: 'text',
          title: 'Insert',
          filterCell: "insertIdFilter",
          hideOn: ['smDown']
        },
        {
          field: 'timeElapsed',
          filterable: true,
          filter: 'text',
          title: 'Time Elapsed',
          filterCell: "timeElapsedFilter",
          cell: "timeElapsedCell",
          hideOn: ['smDown']
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: "typeFilter",
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  watch: {
    resortId () {
      this.getData();
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentRoutingRule(emitValues);
      this.addingElement = false;
      this.show();
    },
    openConfirmModal (index, type) {
      this.deleteIndex = index;
      this.deleteType = type;
      this.$refs.confirmDelete.open();
    },
    closeModal () {
      this.reloadRoutingRules();
    },
    setCurrentRoutingRule (rule) {
      this.routingRule = JSON.parse(JSON.stringify(rule));
    },
    reloadRoutingRules () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getData();
    },
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.get(`/Resort/GetAllRoutingRulesList?resortId=${ this.resortId }`)
      .then(response => {
        this.routingRules = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeRoutingRule () {
      this.axios.delete(`/Resort/RemoveRoutingRule?id=${ this.deleteIndex }&type=${ this.deleteType }&resortId=${ this.resortId }`)
      .then(() => {
        this.$snotify.success(this.$t('routingRulesForms.removedSuccessfullyRoutingRules'));
        this.reloadRoutingRules();
      })
      .finally(() => {
        this.deleteIndex = null;
        this.deleteType = null;
        this.$refs.confirmDelete.close();
      });
    }
  }
}
</script>
<style>
.routingRulesList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
